
import Vue, { PropType } from "vue";

import { mdiContentSave } from "@mdi/js";
import { Activity, Building, Proposal } from "@/interfaces";

export interface DataType {
  content: string;
  category: string;
  activity_date: string;
  menu: boolean;
  valid: boolean;
  building?: Building;
  proposal?: Proposal;
  proposals: Proposal[];
}

export default Vue.extend({
  props: {
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    buildings: {
      type: Array as PropType<Building[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      content: "",
      category: "",
      activity_date: "",
      proposal: undefined,
      building: undefined,
      proposals: [],
      menu: false,
      valid: false,
    };
  },
  computed: {
    proposalItems(): Proposal[] {
      return this.$store.state.proposals;
    },
    icon() {
      return { mdiContentSave };
    },
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"],
      };
    },
    items() {
      return [
        {
          text: "訪問",
          value: "訪問",
        },
        {
          text: "TEL",
          value: "TEL",
        },
        {
          text: "Email",
          value: "Email",
        },
        {
          text: "来店",
          value: "来店",
        },
        {
          text: "その他",
          value: "その他",
        },
      ];
    },
  },
  mounted() {
    this.content = this.activity.content;
    this.category = this.activity.category;
    this.activity_date = this.activity.activity_date;
    this.proposals = this.activity.proposals;
    this.building = this.activity.building;
  },
  methods: {
    close(index: number) {
      this.proposals.splice(index, 1);
    },
    change() {
      if (!this.proposal) {
        return;
      }

      if (
        !this.proposals.find((proposal) => {
          return proposal.id === this.proposal?.id;
        })
      ) {
        this.proposals.push(this.proposal);
      }

      this.proposal = undefined;
    },
    submit() {
      const proposals = this.proposals.map((proposal) => {
        return proposal.id;
      });

      const building_id = this.building ? this.building.id : "";

      this.$emit("save", {
        content: this.content,
        category: this.category,
        building_id,
        activity_date: this.activity_date,
        proposals,
      });
    },
  },
});
