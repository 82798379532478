
import Vue, { PropType } from "vue";
import { Activity, Building, Owner } from "@/interfaces";

import MyEditor from "@/components/activity/MyEditor.vue";

import http from "@/http";

export default Vue.extend({
  components: {
    MyEditor,
  },
  props: {
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
  },
  computed: {
    buildings(): Building[] {
      return this.activity.owner ? this.activity.owner.buildings : [];
    },
  },
  methods: {
    async save(e: any) {
      if (this.activity.owner == null) {
        return;
      }

      const url = `activities/${this.activity.id}`;
      const { data } = await http.put(url, {
        ...e,
      });

      await this.$router.push(`/owner/${this.activity.owner.id}/activities`);
    },
  },
});
